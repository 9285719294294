const Api = {
    // 首页接口
    menu: {method: "get", url: "/web/menu"},//首页菜单查询
    banner_menu: {method: "get", url: "/web/banner/menu/"},//根据菜单id查询banner（每个页面都有顶部广告图片标题栏）
    banner_top: {method: "get", url: "/web/banner/top"}, //首页顶部banner查询（首页顶部出现的轮播图）
    banner_article: {method: "get", url: "/web/banner/article"}, //首页轮播文章查询（首页中央的轮播图）
    banner_fence: {method: "get", url: "/web/banner/fence"}, //首页动态栏查询（首页各种文章框，共6个框）
    get_logo: {method: "get", url: "/web/get/logo"},      //WEB端-友情链接、logo、公众号二维码查询
    article_info: {method: "get", url: "/web/article/info/"},//WEB端-根据文章ID获取文章详情
    menu_info: {method: "get", url: "/web/menu/info/"},//父菜单详情，传menuId
    menu_page: {method: "post", url: "/web/menu/page"}, //菜单分页
    conference_list: {method: "post", url: "/web/conference/list"}, //会议列表分页查询
    conference_info: {method: "get", url: "/web/conference/info/"},//会议首页详情查询(传会议id)
    conference_menu: {method: "post", url: "/web/conference/menu"}, //根据会议菜单获取内容
    conference_register: {method: "post", url: "/web/conference/register"},//参会注册
    search_banner: {method: "get", url: "/web/search/banner/"},
    search_center: {method: "post", url: "/web/search/article"},


    file_download: {method: "get", url: "/web/file/download"},//文件下载

    capture: {method: "get", url: "/web/captcha"}, //验证码接口
    send_code: {method: "get", url: "/web/send/code"}, //短信验证码接口，参数：phone,type:1为登录验证码，2为忘记密码验证码，3为注册验证码
    register_checkCode: {method: "post", url: "/web/register/checkCode"}, //校验验证码是否正确
    conference_dict_list: {method: "get", url: "/web/conference/dict/list"},//注册资料填写字典list
    getAllRegion: {method: "get", url: "/web/baseData/getAllRegion"},//籍贯字典接口
    user_info: {method: "post", url: "/web/user/info"},//个人会员信息查询接口
    reset_password: {method: "post", url: "/web/reset/password"},//找回密码接口，新密码，手机号，验证码
    login_code: {method: "post", url: "/web/login/code"},//验证码登录接口接口，手机号，验证码
    login: {method: "post", url: "/web/login"},//账号密码登录接口，账号，密码，图形验证码
    register: {method: "post", url: "/web/register"},//注册接口
    getQrCode: {method: "get", url: "/wx/getQrCode"},//微信二维码接口
    getQrScanStatus: {method: "get", url: "/wx/getQrScanStatus"},//轮询接口
    wxbind: {method: "post", url: "/wx/bindUser"},//微信绑定用户接口
    wxgetinfo: {method: "get", url: "/wx/getQrLoginToken"},
    getinfo: {method: "post", url: "/web/getMe"}
}

export default Api
